app-calendar-elem {

  .navbar.location-cal {
    background-color: #3c4c61 !important;
  }

  #loadingSpinner {
    width: 100%;

    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    margin: 0 auto;
    text-align: center;
    padding-top: 25%;
    opacity: 0.4;
  }

  .noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
  }

  .month-head {
    flex-basis: 27px;
    background: #292b2c;
    color:white;
    &.location-cal {
      background-color: #3c4c61 !important;;
    }
  }
  .day-row {
    font-size:11px;
    flex-basis: 20px;
    border-top: 1px solid #eeeeee;

    &.active {
      background-color: #eeeeee;
    }
    &:last-of-type {
      border-bottom: 1px solid #eeeeee;
    }

    &.sunday .day {
      color: darkred;
    }
    .day {
      width: 35px;
      color: #aaaaaa;

    }
    .event {
      padding: 0 1px;
      min-width: 13px;
      float: left;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      @media not all and (min-resolution:.001dpcm)
      { @supports (-webkit-appearance:none) {
          // fix safari bug https://stackoverflow.com/questions/35012943/text-overflow-hides-text-when-it-shouldnt-in-safari
          &:after {
            content: "\0000a0";
            display: inline-block;
            width: 0;
          }
        }
      }


      .uppercase {
        text-transform: uppercase;
      }

      &.request {
        color: #000000;
      }
      &.confirmed {
        color: #fc091b;
      }

      &.contract {
        color: #0c7f12;
      }
      &.invisible-for-artist  {
        background-color: rgba(251, 249, 84, 0.63)
      }
      &.cancelled {
        color: #808080;
        text-decoration: line-through;
      }

      &.newprod {
        border: 1px solid blueviolet;
      }

      &.pull-right {
        color: #023f88 !important;
      }


      &.dragable {
        border: 1px solid #dddddd;
        background: #ccc;
        span, fa {
          cursor: move !important;
        }
      }

    }

  }

  .month-col {
    border-right: 1px solid #ddd;
    &.last {
      border-right: none !important;
    }
  }
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}


.cdk-drop-list-dragging .example-box:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}


.cdk-drag-preview.event {
  padding: 0 1px;
  min-width: 13px;
  float: left;
  overflow: hidden;
  white-space: nowrap;
  font-size: 11px;
  background: #cccccc;
    .uppercase {
      text-transform: uppercase;
    }

    &.request {
      color: #000000;
    }
    &.confirmed {
      color: #fc091b;
    }

    &.contract {
      color: #0c7f12;
    }
    &.cancelled {
      color: #808080;
      text-decoration: line-through;
    }

    &.newprod {
      border: 1px solid blueviolet;
    }

    &.pull-right {
      color: #023f88 !important;
    }


    &.dragable {
      border: 1px solid #dddddd;
      background: #ccc;
      span, fa {
        cursor: move !important;
      }
    }

  }

